<template>
  <div>
    <b-row>
      <b-col>
        <b-card>
          <b-row>
            <b-col>
              <div class="d-flex justify-content-between align-items-center">
                <h4 class="m-0">Product View</h4>
                <b-button variant="red" squared @click="openModal" size="sm">Delete</b-button>
              </div>
            </b-col>
          </b-row>

          <b-row class="h-100" v-if="state === 'loading'" align-h="center">
            <b-spinner label="Loading..."></b-spinner>
          </b-row>
          
          <div v-if="state === 'show'">
            <b-row>
              <b-col cols="4">
                <label>Product Reference</label>
                <b-form-input v-model="selectedProduct.productRef"></b-form-input>
              </b-col>
              <b-col cols="4">
                <label>Cost Before VAT <span class="text-red">*</span></label>
                <b-form-input v-model="$v.selectedProduct.priceExVat.$model" @blur="$v.selectedProduct.priceExVat.$touch()"></b-form-input>
                <div v-if="$v.selectedProduct.priceExVat.$error" class="text-red font-weight-400 text-left">
                  <p v-if="!$v.selectedProduct.priceExVat.decimal" >Please enter a valid number</p>
                  <p v-if="!$v.selectedProduct.priceExVat.required" >This is a required field</p>
                </div>
              </b-col>
              <b-col cols="4">
                <label>Manufacturer</label>
                <b-form-input v-model="selectedProduct.manufacturer"></b-form-input>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="4">
                <label>Short Description</label>
                <b-form-input v-model="selectedProduct.description"></b-form-input>
              </b-col>
              <b-col cols="4">
                <label>Item Code</label>
                <b-form-input v-model="selectedProduct.itemCode"></b-form-input>
              </b-col>
            </b-row>
            <hr class="mx-3">
            <b-row>
              <b-col>
                <div class="d-flex justify-content-end">
                  <div>
                    <b-button variant="red" squared @click="goBackToSearch" class="ml-2">Cancel</b-button>
                  </div>
                  <div>
                    <b-button variant="primary" squared @click="updateProductDetails" class="ml-2" :disabled="$v.selectedProduct.$invalid">Save</b-button>
                  </div>
                </div>
              </b-col>
            </b-row>
          </div>
        </b-card>
      </b-col>
    </b-row>
    <b-modal id="stockModal" hide-footer hide-header-close class="text-center" title="Delete Product">

      <b-row>
        <b-col cols="12">
          <label class="text-center">Are you sure you want to delete <span class="font-weight-bold text-red text-center">{{this.selectedProduct.productRef}}</span>?</label>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" class="text-center mt-3">
          <b-button variant="red" @click="hideModal" squared class="mr-2" >Cancel</b-button>
          <b-button variant="red" @click="removeProduct" squared >Delete</b-button>
        </b-col>
      </b-row>
      <div class="d-block"></div>
    </b-modal>
  </div>
</template>


<script>
import {mapActions, mapMutations, mapState} from "vuex";
import {decimal, required} from "vuelidate/lib/validators";

export default {
  name: "productView",
  
  data: () => ({
    state: 'loading',
    product: {
      productRef: null,
      priceExVat: null,
      TypeOfVat: null,
      description: null,
      itemCode: null,
      active: null,
      manufacturer: null,
    },
  }),
  created() {
    this.getSelectedProduct()
    this.setBreadcrumb([
      {
        text: 'Product'
      },
      {
        text: 'Details'
      },
    ])
  },
  methods: {
    ...mapActions(["getProductDetail", "updateProduct", "deleteProduct"]),
    ...mapMutations('breadcrumbService', ['setBreadcrumb']),

    getSelectedProduct() {
      if (this.$store.state.selectedProduct == null){
        this.$router.push({path: '/admin/StockSearch'})
      }
    this.getProductDetail()
    .then((response) => {
      this.$store.commit('setSelectedProduct', response.data)
      this.state = 'show'
      })
    },
    
    removeProduct() {
      this.state = 'loading'
      this.selectedProduct.active = false
      this.$store.commit('selectedProduct', this.product)
      this.deleteProduct()
      .then(() => {
        this.$router.push({path: '/admin/StockSearch'})
      })
    },
    
    goBackToSearch(){
      this.$router.push({path: '/admin/StockSearch'})
    },
    
    updateProductDetails() {
      this.$store.commit('selectedProduct', this.product)
      this.state = 'loading'
      this.updateProduct()
        .then((response) => {
          response.status === 200 ? this.goBackToSearch() : null
        })
      .catch(() => {
        this.state = 'show'
      })
    },

    openModal() {
      this.$bvModal.show('stockModal')
    },

    hideModal() {
      this.$bvModal.hide('stockModal')
    },
    
  },
  computed: {
    ...mapState([
        "selectedProduct"
    ])
  },
  validations: {
    selectedProduct: {
      priceExVat: {decimal, required},
    }
  },
}
</script>

<style scoped>

</style>